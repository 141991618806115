<template>
  <div>
    <Header />
    <div class="resources-header-section">
      <div class="container mt-5 text-center">
        <h1>Privacy Policy</h1>
        <p>Your privacy is important to us. Read below to learn more about how we handle your information.</p>
      </div>
    </div>
    <div class="container resources-container my-5">
      <!-- Welcome Section -->
      <section class="mb-5">
        <h2>Welcome!</h2>
        <p>
          We’re happy to have you here! We’ve done our best to write this policy in a way that is as easy to understand as
          possible, trying to avoid difficult to understand legalese or technical terms, or at least explain them when we can.
          But if anything is unclear, or if you have feedback for us that would make this easier to understand, please contact us.
        </p>
        <p>
          First and foremost, everything we do is with the intent of improving the health and well-being of the people using
          our products and services. Our legal mission statement is to “maximize positive impact on the world’s health and
          well-being through the creation of innovative products, services, education, content and community.” We are
          grateful that you are considering us!
        </p>
        <p>
          PeakMind (“PeakMind LLC,” “we,” “us,” or “our”) provides a wellness app for you to learn the tools you need to
          navigate the mental health space through gamification (such services, including peakmind.com (the “Site”)
          and any related mobile applications, are referred to collectively in this Privacy Policy as the “Services”).
        </p>
        <p>
          This Privacy Policy explains what personal information we collect, how we use and share that information, and
          your choices concerning our information practices.
        </p>
        <p>
          Before using the Services or submitting any personal information to PeakMind LLC, please review this Privacy
          Policy carefully and contact us if you have any questions. By using the Services, you agree to the practices
          described in this Privacy Policy. If you do not agree to this Privacy Policy, please do not access the Site or
          otherwise use the Services.
        </p>
      </section>

      <!-- Our Principles Section -->
      <section class="mb-5">
        <h2>Our Principles</h2>
        <p>
          When you entrust us with sensitive and personal information, we believe it is our most important responsibility to
          keep it as safe, secure, and private as we can, while still delivering you effective features and services in our
          products.
        </p>
        <p>
          We also believe any private personal information you decide to share with others must be done with your explicit
          consent, in easy to understand language, and you should always have the option to remove that consent later if
          you change your mind. And even in these situations we still take every precaution to keep that information as
          private and secure as possible.
        </p>
        <p>
          This is our commitment to you. And while we will do what we can to prevent any mistakes on our end, if we ever
          falter, we will do everything we can to make it right and regain your trust.
        </p>
      </section>

      <!-- Why We Store Data Section -->
      <section class="mb-5">
        <h2>Why We Store Data</h2>
        <p>
          You may ask, if we want to protect the privacy and information of the people using our products, why do we store
          any data at all?
        </p>
        <p>
          There are some cases where it is the only way to deliver the Services. For example, in order for you to access
          your account on multiple devices or to save a backup for you for recovery purposes, we need to store your data
          on our servers.
        </p>
        <p>
          The other critical reason we store data is to make our products better for you and everyone else who could be
          helped by them. The only way for us to provide engaging and personalized mental health content is to continue
          improving our products, and the only way we can do that is to figure out what works best for the people using our
          products. This stems from analyzing data, and to do it in a way that preserves the privacy of everyone using our
          products in order to continue maintaining and earning your trust.
        </p>
        <p>
          Improving everyone’s mental health, physical health, and well-being is a very difficult problem, but also a very
          important one, and we are grateful for your help and trust. Your participation makes this possible and we greatly
          appreciate it.
        </p>
      </section>

      <!-- Information We May Store Section -->
      <section class="mb-5">
        <h2>Information We May Store</h2>
        <p>
          In order to provide you the Services, and to continuously improve our products for mental health, physical health,
          and well-being outcomes, we may store the information outlined here. We want you to be able to understand what we
          are storing, when, and why, so you can feel as comfortable as possible with the Services. If we haven’t done a
          good enough job explaining any of the following, please contact us at PeakMind.com.
        </p>

        <h3>Personal Information from You We May Store:</h3>
        <ul>
          <li>
            <strong>Identification Information:</strong> If you choose to provide it, we will store your name, email
            address, mailing/billing addresses, gender, age, and birthday. With your permission, we may also connect with
            your phone’s address book and if you choose to share achievements with your contacts. The contact information
            (e.g. phone numbers or emails) of the contacts you choose will be accessed to share achievements and may be
            temporarily stored for sharing.
          </li>
          <li>
            <strong>Financial Information:</strong> Our payment processor(s) will collect the financial information
            necessary to process your payments, such as your payment card number and authentication details. Please note,
            however, that we store only a tokenized version of such information and do not maintain payment card information
            on our servers.
          </li>
          <li>
            <strong>Communication Information:</strong> We may collect information when you contact us with questions or
            concerns and when you voluntarily respond to questionnaires, surveys or requests for market research seeking
            your opinion and feedback.
          </li>
          <li>
            <strong>Commercial Information:</strong> We may retain a history of the products you browse and/or purchase
            using the Services, such as Guardian memberships.
          </li>
          <li>
            <strong>Social Media Information:</strong> We maintain a social media presence on platforms like Instagram,
            YouTube, TikTok, and LinkedIn (“Social Media Pages”). When you interact with us on social media, we may receive
            personal information that you provide or make available to us based on your settings, such as your contact
            details. In addition, the companies that host our Social Media Pages may provide us with aggregate information
            and analytics regarding the use of our Social Media Pages.
          </li>
          <li>
            <strong>Writing and Content Information:</strong> If you choose to enable a feature that lets you create an
            account in order to access your account on different devices or fully recover your account, we may store the
            full content you write in reflections, custom goals, or custom journeys on our servers in an encrypted way.
          </li>
          <li>
            <strong>Internet Activity Information:</strong> When you use the Services, we may automatically log the
            following information:
            <ul>
              <li><strong>Device Information:</strong> The manufacturer and model, operating system, browser type, IP
                address, and unique identifiers of the device you use to access the Services. The information we collect
                may vary based on your device type and settings. All of this happens pretty much automatically for every
                service and product you use that connects to a server on the internet, not just ours, but we are required to
                include a notification about it here in the Privacy Policy.
              </li>
              <li><strong>Usage Information:</strong> Information about how you use our Services, such as the types of
                content that you view or engage with, the features you use, the actions you take, and the time, frequency,
                and duration of your activities.
              </li>
              <li><strong>Location Information:</strong> We may derive a rough estimate of your location from your IP address
                when you use the Services.
              </li>
              <li><strong>Email Open/Click Information:</strong> We may collect information about the date and time you open
                or click links in emails we send, as is provided by default by third-party service providers.
              </li>
            </ul>
          </li>
        </ul>

        <h3>We May Use the Following Technologies to Collect Internet Activity Information:</h3>
        <ul>
          <li>
            <strong>Cookies:</strong> Text files stored on your device to uniquely identify your browser or to store
            information or settings in the browser to help you navigate between pages efficiently, remember your
            preferences, enable functionality, help us understand user activity and patterns, and facilitate online
            advertising.
          </li>
          <li>
            <strong>Local Storage Technologies:</strong> Like HTML5, that provide cookie-equivalent functionality but can
            store larger amounts of data, including on your device outside of your browser in connection with specific
            applications.
          </li>
        </ul>
      </section>

      <!-- Use of Information Section -->
      <section class="mb-5">
        <h2>Use of Information</h2>
        <p>We use the information we store for the following purposes:</p>

        <h3>Service Improvement</h3>
        <p>To make our Services better for you and everyone else who could be helped by it, including to:</p>
        <ul>
          <li>Improve the Services and create new features;</li>
          <li>Personalize your experience; and</li>
          <li>Create and derive insights from de-identified and aggregated information.</li>
        </ul>
        <p>
          For example, when you use the Services, we may store information related to your activity, such as counts of
          activities you have done or selected. We aggregate and analyze this information across the people using the
          Services in order to better understand how people are using our products, and to determine how we can better
          deliver existing and new features and services that help us meet our mission statement and improve people’s
          mental health, physical health, growth, and well-being. As a concrete example, by storing how often people use
          two different versions of a feature we make available, we can understand which version best helps people and
          best meets our goals.
        </p>

        <h3>Communicating with You</h3>
        <p>Including to:</p>
        <ul>
          <li>Send you updates about administrative matters such as changes to our terms or policies;</li>
          <li>Provide user support, and respond to your requests, questions and feedback.</li>
        </ul>

        <h3>Service Delivery</h3>
        <p>So you can participate in the Services, including to:</p>
        <ul>
          <li>Provide, operate, maintain, and secure the Services;</li>
          <li>Create, maintain, and authenticate your account;</li>
          <li>Process transactions through our third-party payment processors.</li>
        </ul>

        <h3>Marketing and Advertising</h3>
        <p>Including for:</p>
        <ul>
          <li>
            <strong>Direct Marketing:</strong> To send you marketing communications, including notifying you of special
            promotions, offers and events via email and other means.
          </li>
          <li>
            <strong>Promotional Campaigns:</strong> To help more people learn about the Services and do our best to help
            more people, we may run promotional campaigns with third-party service providers. To make sure that these are
            working, that we do not lose money on the campaigns, and that we’re reaching people who may be helped by the
            Services, we may share the minimum information possible to effectively run, improve, and learn about the
            performance of these campaigns with service providers. You have choices, and can opt out of any marketing
            preferences at any time.
          </li>
        </ul>

        <h3>Compliance and Protection</h3>
        <p>Including to:</p>
        <ul>
          <li>Comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities;</li>
          <li>Protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims);</li>
          <li>Audit our compliance with legal and contractual requirements and internal policies;</li>
          <li>Prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal activity, including cyberattacks and identity theft.</li>
        </ul>
      </section>

      <!-- Sharing of Information Section -->
      <section class="mb-5">
        <h2>Sharing of Information</h2>
        <p>
          <strong>Our Sharing:</strong> We do not sell, rent, license, or lease your personal information to third parties.
          However, we may share personal information with:
        </p>
        <ul>
          <li>
            <strong>Service Providers:</strong> Including hosting services, email services, advertising and marketing
            services, payment processors, customer support services, and analytics services. We take commercially reasonable
            steps to ensure our service providers adhere to the security standards we apply to your personal information.
            PeakMind integrates with third-party services such as OpenAI to provide analysis features such as personalized
            mental health insights and conversational support. Your privacy is a top priority, and we ensure that any data
            shared with OpenAI is used solely to enhance your experience within the app. No personal data is used for
            advertising, marketing, or sold to third parties. Data processed by OpenAI is handled securely and in compliance
            with privacy standards, ensuring that all interactions are anonymized and encrypted. At any time, you can
            request the deletion of any information associated with your use of AI features.
          </li>
          <li>
            Additionally, PeakMind integrates with Apple HealthKit to enhance your wellness journey by allowing you to track
            and manage various health metrics seamlessly. We respect your privacy and ensure that any health data collected
            through HealthKit is used solely for improving your experience within the app, such as providing personalized
            wellness insights. PeakMind does not use HealthKit data for advertising, marketing, or sales purposes. Health data
            is only accessed or shared with your explicit consent, and you have full control over which data types you allow
            us to read or write. We strictly adhere to Apple’s guidelines, ensuring that your information remains private and
            secure, with all HealthKit data encrypted and processed in a way that protects your privacy. At any time, you can
            revoke our access to HealthKit data through the settings on your device.
          </li>
          <li>
            <strong>Professional Advisors:</strong> Such as lawyers and accountants, where doing so is necessary to
            facilitate the services they render to us.
          </li>
          <li>
            <strong>Business Transaction Recipients:</strong> Such as counterparties and others assisting with a merger,
            acquisition, financing, reorganization, bankruptcy, receivership, dissolution, asset sale, or similar transaction,
            and with successors or affiliates as part of or following that transaction.
          </li>
          <li>
            <strong>Government Authorities:</strong> Where required to do so for the Compliance and Protection purposes
            described above.
          </li>
          <li>
            <strong>Affiliates:</strong> We may share personal information with our current and future affiliates, meaning an
            entity that controls, is controlled by, or is under common control with PeakMind LLC. Our affiliates may use the
            personal information we share in a manner consistent with this Privacy Policy.
          </li>
        </ul>

        <p>
          <strong>Your Sharing:</strong> Through the Services, you may be able to make your personal information available to
          others if you choose to do so, including:
        </p>
        <ul>
          <li>Other users and the public, when you share or post content, or choose to engage in public transactions through the Services;</li>
          <li>Social media platforms, when you choose to share content on social media; and</li>
          <li>In other ways with your permission, if you have given your explicit consent to do so.</li>
        </ul>

        <p>
          We do not run ads for other companies on the Services, so we do not need to share the data companies that do run ads
          on their products need to. The company is supported by subscriptions and other ways people directly contribute to
          the company. We do not sell your information to third parties for money.
        </p>
      </section>

      <!-- Personal Information Retention Section -->
      <section class="mb-5">
        <h2>Personal Information Retention</h2>
        <p>
          We keep personal information until we no longer need it to satisfy the purposes described in this Privacy Policy and
          we have no legal obligation to keep it.
        </p>
      </section>

      <!-- Children Section -->
      <section class="mb-5">
        <h2>Children</h2>
        <p>
          Our Services are not directed to children who are under the age of 13. We do not knowingly collect personal
          information from children under the age of 13. If we learn that we have collected personal information from a
          child under the age of 13 without the consent of the child’s parent or guardian as required by law, we will
          delete that information.
        </p>
        <p>
          While we would like to make some of the Services available to children under 13, various countries have laws and
          regulations that require significant investment and cost on our part to demonstrate compliance, even if the
          Services would already be beneficial to people of this age. In the future we hope to have the resources to make
          some of our Services meet these requirements.
        </p>
      </section>

      <!-- Notice to California Residents Section -->
      <section class="mb-5">
        <h2>Notice to California Residents</h2>
        <p>
          Where provided for by law and subject to any applicable exceptions, California residents may have the following
          rights:
        </p>
        <ul>
          <li>
            <strong>Know:</strong> You can request to know the categories of personal information that PeakMind LLC has
            collected about you, the business purpose for collecting your personal information, the categories of sources
            from which the personal information was collected, whether PeakMind LLC has disclosed your personal information
            for business purposes, the categories of personal information so disclosed, and the categories of third
            parties to whom we have disclosed your personal information (we provide this information in the Personal
            Information Collection, Use, and Sharing sections above);
          </li>
          <li>
            <strong>Access:</strong> You can request access to the specific pieces of personal information that PeakMind LLC
            has collected about you;
          </li>
          <li>
            <strong>Delete:</strong> You can request that we delete the personal information we collected from you; and
          </li>
          <li>
            <strong>Opt Out:</strong> We may share Identification Information and Internet Activity Information with
            social media platforms and other advertising partners for the purpose of serving you personalized advertisements
            from us on social media platforms and other third-party websites, such as to prevent showing advertisements
            from us if you already use the Services – under certain regulations such sharing may be considered a “sale”
            of personal information. To opt out, please contact us at <a href="mailto:trevord@peakmind.com">trevord@peakmind.com</a>.
          </li>
        </ul>
        <p>
          If you would like to exercise any of these rights, have any questions, or want an alternative form of this Privacy
          Policy, please contact us. We want to be as clear as we can with you about your options and the consequences of
          exercising certain options. After we receive your request, we may request additional information from you to verify
          your identity. Your authorized agent may submit requests in the same manner, although we may require the agent to
          present signed written permission to act on your behalf, and you may also be required to independently verify your
          identity with us and confirm that you authorized the agent to submit the request. We will not treat you differently
          for exercising your rights.
        </p>
      </section>

      <!-- Job Applicants Section -->
      <section class="mb-5">
        <h2>Job Applicants</h2>
        <p>
          When you apply for a job with PeakMind LLC, we collect the information that you provide in connection with your job
          application. This includes name, contact information, professional credentials and skills, educational and work
          history, and other information that may be included in a resume or provided during interviews (which may be recorded).
          This may also include demographic or diversity information that you voluntarily provide. We may also conduct background
          checks and receive related information.
        </p>
        <p>
          We use applicants’ information to facilitate our recruitment activities and process employment applications,
          including evaluating candidates and monitoring recruitment statistics. We use successful applicants’ information
          to administer the employment relationship. We may also use and disclose applicants’ information (a) to improve
          our website, (b) as otherwise necessary to comply with relevant laws, (c) to respond to subpoenas or warrants served
          on PeakMind LLC, and (d) to protect and defend the rights or property of PeakMind LLC or others.
        </p>
      </section>

      <!-- Links to Other Websites Section -->
      <section class="mb-5">
        <h2>Links to Other Websites</h2>
        <p>
          The Services may contain links to other websites not operated or controlled by PeakMind LLC, including social media
          services (“Third Party Sites”). The information that you share with Third Party Sites will be governed by the specific
          privacy policies and terms of service of the Third Party Sites and not by this Privacy Policy. By providing these
          links we do not imply that we endorse or have reviewed these sites. Please contact the Third Party Sites directly for
          information on their privacy practices and policies.
        </p>
      </section>

      <!-- Keeping Your Information Secure Section -->
      <section class="mb-5">
        <h2>Keeping Your Information Secure</h2>
        <p>
          One of the best ways to keep information secure is not to store it at all, and that’s why we do our best to only store
          data that’s necessary to provide you our Services and to meet our mission statement to maximize positive impact on
          the world’s health and well-being. When we do store information, we employ a number of technical, organizational and
          physical safeguards designed to protect the personal information we collect. However, we cannot guarantee the
          100% absolute security of your personal information and you use the Services at your own risk.
        </p>
      </section>

      <!-- Your Rights and Choices Section -->
      <section class="mb-5">
        <h2>Your Rights and Choices</h2>

        <h3>Update or Correct Personal Information</h3>
        <p>
          You can contact us and request any updates or corrections needed to keep your personal information accurate, current,
          and complete.
        </p>

        <h3>Opt Out of Marketing Communications</h3>
        <p>
          You may opt out of marketing communications by following the opt out or unsubscribe instructions in any marketing
          email we send you. Please note, however, that you may continue to receive communications as described in the
          Communicating with You section after opting out of marketing communications.
        </p>

        <h3>Limit Online Tracking</h3>
        <p>
          You also have controls at your disposal to limit online tracking, a common use of which is to opt out of receiving
          personalized ads served by service providers we may engage with. Recall that we may run promotional campaigns with
          third-party service providers. To make sure that these are working, that we do not lose money on the campaigns, and
          that we’re reaching people who may be helped by the Services, we may share the minimum information possible to
          effectively run, improve, and learn about the performance of these campaigns with service providers. While we believe
          that advertising, when done responsibly and with reliable third party services, serves to enable small businesses and
          mom-and-pop shops to have a chance to succeed and compete with much larger companies, with minimal risk to privacy,
          we understand that you may not agree and that there are companies that can abuse this ability, so we want to outline
          ways you can limit online tracking. In all cases below, even if you take these actions, you will still see advertisements,
          but they will likely be less relevant to you and it will likely make it more difficult for small and medium sized
          businesses to succeed.
        </p>

        <h4>Block Cookies</h4>
        <p>
          Most browsers let you remove or reject cookies, including cookies used for interest-based advertising or online tracking.
          These are typically known as third-party cookies. To do this, follow the instructions in your browser settings. Many
          browsers accept cookies by default until you change your settings. Use the following links to learn more about how to
          control cookies and online tracking through your browser:
        </p>
        <ul>
          <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank">Firefox</a></li>
          <li><a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank">Chrome</a></li>
          <li><a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank">Microsoft Edge</a></li>
          <li><a href="https://support.apple.com/en-us/HT201265" target="_blank">Safari</a></li>
        </ul>

        <h4>Online Tracking Opt-Out</h4>
        <p>You can opt out of interest-based advertising as described in our <a href="#">Cookie Policy</a>.</p>

        <h4>Limit the Use of Advertising ID</h4>
        <p>
          You may be able to limit use of your mobile device’s advertising ID for interest-based advertising purposes through your
          device’s settings. Both Google and Apple have ways to do this for their platforms:
        </p>
        <ul>
          <li><a href="https://support.google.com/googleplay/answer/6048248?hl=en" target="_blank">Opt out of Interest-Based Ads on Android devices</a></li>
          <li><a href="https://support.apple.com/en-us/HT208343" target="_blank">Limit Ad Tracking on iOS devices</a></li>
        </ul>

        <h4>Use Privacy Plug-Ins or Browsers</h4>
        <p>
          You can block the setting of cookies used for interest-based ads by using a browser with privacy features, like Brave, or
          installing browser plugins like Privacy Badger, DuckDuckGo, Ghostery or uBlock Origin, and configuring them to block
          third-party cookies/trackers. You can also install a browser add-on to opt out of Google Analytics.
        </p>

        <h4>Advertising Industry Opt-Outs</h4>
        <p>
          You can also use these opt-out options to limit use of your information for interest-based advertising by participating
          companies:
        </p>
        <ul>
          <li><a href="http://www.networkadvertising.org/choices/" target="_blank">The NAI's opt-out platform</a></li>
          <li><a href="http://www.youronlinechoices.com/" target="_blank">The EDAA's opt-out platform</a></li>
          <li><a href="http://optout.aboutads.info/?c=2&lang=EN" target="_blank">The DAA's opt-out platform</a></li>
        </ul>

        <h4>Platform Opt-Outs</h4>
        <p>
          The following advertising platforms offer opt-out features that let you opt out of use of your information for
          interest-based advertising. We don’t use all of these third-party advertising services, but we are listing them here
          for your convenience:
        </p>
        <ul>
          <li><a href="https://adssettings.google.com/authenticated" target="_blank">Google: Control the ads you see</a></li>
          <li><a href="https://www.apple.com/legal/privacy/en-ww/" target="_blank">Apple: Control personalized ads</a></li>
          <li><a href="https://www.linkedin.com/psettings/advertising" target="_blank">LinkedIn: Manage advertising preferences</a></li>
          <li><a href="https://www.facebook.com/settings/?tab=ads" target="_blank">Facebook: Ad preferences</a></li>
          <li><a href="https://www.tiktok.com/legal/privacy-policy?lang=en" target="_blank">TikTok: Change your personalized ads settings</a></li>
          <li><a href="https://help.instagram.com/198845941570870" target="_blank">Instagram: Ads on Instagram</a></li>
          <li><a href="https://support.snapchat.com/en-US/a/personalization" target="_blank">Snapchat: Adjust your advertising & interest preferences</a></li>
          <li><a href="https://account.microsoft.com/privacy/ad-settings" target="_blank">Microsoft: Ad settings</a></li>
          <li><a href="https://help.pinterest.com/en/article/personalization-settings" target="_blank">Pinterest: Personalization and data</a></li>
          <li><a href="https://www.reddit.com/policies/privacy-policy" target="_blank">Reddit: Personalization preferences</a></li>
          <li><a href="https://www.amazon.com/adprefs" target="_blank">Amazon: Advertising preferences</a></li>
        </ul>
        <p>
          Note that some of the above opt-out mechanisms are specific to the device or browser on which they are exercised. You
          will need to opt out on every browser and device that you use when that is the case.
        </p>

        <p>
          In all likelihood, most of the sites and apps you know and love that show ads are probably using interest-based
          advertising to try to earn enough to stay in business. And many small and medium sized businesses and apps that you
          may frequent or use can only exist, find customers, and succeed with the help of interest-based advertising. While bad
          actors certainly exist, we believe that the third-party services above strike a good balance of preserving your privacy
          while enabling you to see relevant advertising through personalization for products and services you actually want, and
          that also helps support small businesses and competition around the world. And unfortunately much of how this works is
          not well understood. But it is your choice, and we want you to be able to make changes easily with the information
          linked above. Keep in mind that you will still see advertisements in all these cases, but they will be less relevant
          to you.
        </p>

        <h3>Personal Information Requests</h3>
        <p>
          We also offer you choices that affect how we handle the personal information that we control. You may request the
          following in relation to your personal information:
        </p>
        <ul>
          <li>Information about how we have collected and used your personal information. We have made this information available to you without having to request it by including it in this Privacy Policy.</li>
          <li>Access to a copy of the personal information that we have collected about you. Where applicable, we will provide the information in a portable, machine-readable, readily usable format.</li>
          <li>Correction of personal information that is inaccurate or out of date.</li>
          <li>Deletion of personal information that we no longer need to provide the Services or for other lawful purposes.</li>
          <li>Additional rights, such as to object to and request that we restrict our use of your personal information, and where applicable, you may withdraw your consent.</li>
        </ul>
        <p>
          To alternatively make a request, please email us or contact us from our website. We may ask for specific information
          from you to help us confirm your identity. California residents can empower an “authorized agent” to submit requests
          on their behalf. We will require authorized agents to confirm their identity and authority, in accordance with
          applicable laws. You are entitled to exercise the rights described above free from discrimination.
        </p>

        <h3>Limits on Your Choices</h3>
        <p>
          In some instances, your choices may be limited, such as where fulfilling your request would impair the rights of
          others, our ability to provide a service you have requested, or our ability to comply with our legal obligations and
          enforce our legal rights. If you are not satisfied with how we address your request, you may submit a complaint by
          contacting us as provided in the “Contact Us” section below.
        </p>

        <h3>Do Not Track</h3>
        <p>
          Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit. We
          currently do not respond to “Do Not Track” or similar signals. To find out more about “Do Not Track,” please visit
          <a href="http://www.allaboutdnt.com" target="_blank">http://www.allaboutdnt.com</a>.
        </p>
      </section>

      <!-- International Data Transfers Section -->
      <section class="mb-5">
        <h2>International Data Transfers</h2>
        <p>
          Since we are based in the United States, you will provide your data directly to us in the United States. We may
          transfer your personal information to our affiliates and service providers in the United States and other
          jurisdictions. Please note that such jurisdictions may not provide the same protections as the data protection laws
          in your home country.
        </p>
        <p>
          In these instances we will ensure that relevant safeguards are in place to afford adequate protection for your
          personal information and we will comply with applicable data protection laws, in particular by relying on an EU
          Commission or UK government adequacy decision or on contractual protections for the transfer of your personal
          information. For more information about how we transfer personal information internationally, please contact us as
          set out in the “Contact Us” section below.
        </p>
      </section>

      <!-- Changes to the Privacy Policy Section -->
      <section class="mb-5">
        <h2>Changes to the Privacy Policy</h2>
        <p>
          The Services and our business may change from time to time. As a result, we may change this Privacy Policy at any
          time. When we do, we will post an updated version on this page, unless another type of notice is required by applicable
          law. By continuing to use our Services or providing us with personal information after we have posted an updated Privacy
          Policy, or notified you by other means if applicable, you consent to the revised Privacy Policy and the practices
          described in it.
        </p>
      </section>

      <!-- Contact Us Section -->
      <section class="mb-5">
        <h2>Contact Us</h2>
        <p>
          If you have any questions about the jargon in our Privacy Policy or information practices, or we haven’t done a good
          enough job explaining any of the Privacy Policy, please feel free to contact us at our designated request address:
          <a href="mailto:trevord@peakmind.com">trevord@peakmind.com</a>. You can also send us mail to PeakMind LLC at 5 Millmont St,
          Roxbury, MA 02119.
        </p>
      </section>
    </div>
    <CallToAction />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import CallToAction from '../components/CallToAction.vue'

export default {
  name: 'PrivacyPolicy',
  components: {
    Header,
    CallToAction,
    Footer
  }
}
</script>

<style scoped>
.resources-header-section {
  background: url("https://i.imgur.com/HF56KSh.png") no-repeat center center;
  background-size: cover;
  padding: 100px 0; 
  position: relative;
  height: 40vh;
}

.resources-header-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.0); 
  z-index: 1;
}

.resources-header-section .container {
  position: relative;
  z-index: 2;
  color: white;
}

.container {
  max-width: 900px;
  margin: auto;
  padding: 0 1rem;
}

h1 {
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 20px;
}

h2 {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 15px;
}

h3 {
  font-weight: 600;
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
}

h4 {
  font-weight: 600;
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
}

p {
  font-size: 18px;
  line-height: 1.6;
}

ul {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

li {
  margin-bottom: 0.5rem;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.resources-container {
  padding: 50px 0;
}
</style>
